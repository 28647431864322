body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(231, 235, 240) !important;
  display: flex;
  flex-direction: column;

}

h1 {
  font-size: 2.0rem !important;
}

h2 {
  font-size: 1.7rem !important;
}

h3 {
  font-size: 1.4rem !important;
}

h4 {
  font-size: 1.1rem !important;
}


#main {

  max-width: 1300px;
  margin: 0 auto !important;
  float: none !important;
}

#footer {
  font-size: 2vw !important;
}

@media only screen and (max-width: 600px) {
  #burger_menu {
    display: flex;
  }

  #header_link_bar {
    display: none;
  }

  #footer {
    font-size: 4vw !important;
  }
}

@media only screen and (min-width: 601px) {
  #burger_menu {
    display: none;
  }

  #header_link_bar {
    display: flex;
  }
}


.start_now_button {
  font-size: 2vw !important;
  color: white !important;
}

.feature_headline {
  font-size: 6vw !important;
}

@media only screen and (max-width: 600px) {
  .start_now_button {
    font-size: 5vw !important;
  }


}

@media only screen and (max-width: 800px) {
 

  #documentation_menu {
    position: absolute;
    top: 85px;
    left: -100%;
    width: 250px;

  }

  #open_documentation_container {
    display: flex;
  }
}

@media only screen and (min-width: 801px) {
  #documentation_menu {
    /* width: 400px; */
    flex: 0 0 400px;
    position: relative;

  }

  #open_documentation_container {
    display: none;
  }

}

@media only screen and (min-width: 1200px) {
  .start_now_button {
    font-size: 2vw !important;
  }

  .feature_headline {
    font-size: 8vw !important;
  }

}



#documentation {
  display: flex;
  flex-direction: row;
}


#documentation a,
#documentation a:hover,
#documentation a:visited {
  /* color: #000000; */
  text-decoration: none;
}


#documentation_menu {

  background-color: #006600;

  flex-direction: column;
  /* justify-content: center; */

  /* transition: 850ms; */
  padding-bottom: 100px;
}

#documentation_menu.active {
  left: 0;
  transition: 350ms;
}

#documentation .navigation_section {
  font-weight: 600;
  /* padding:10px; */
}


#documentation .navigation_link_container {

  padding: 5px;
  padding-left: 15px;
}

#documentation .navigation_link_container:hover {
  background-color: #004400;
}

#documentation .navigation_link {
  font-weight: 300;
  display: block;
  color: #cccccc;
}

#documentation .navigation_section_title {
  margin-top: 15px;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  font-size: larger;
  color: #ccc;
  /* border-top: 1px solid white; */
  text-decoration: underline;
}

#documentation .navigation_link:hover {
  /* font-weight: 500; */
  /* display:block; */
  /* color: #fff; */

}

#documentation_content {
  padding: 10px;
}

#documentation .documentation_crosslink {
  color: #000;
  font-weight: 600;
  text-decoration: underline;
}

#documentation .documentation_crosslink:hover {

  font-weight: 800;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
a:hover,
a:visited {
  color: #ffffff
}

a:hover {
  font-weight: 800;
}


#signup a,
#signup a:visited {
  color: #006600;
  text-decoration-color: #006600;
  font-weight: bold;
}

#signup a:hover {
  text-decoration: none;
}

#root {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 4.5rem;
  /* Footer height */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4.5rem;
  /* Footer height */
}

#imprint a {
  color: black;
}